/* #navbar-header {
  position: fixed !important;
  top: 0px;
  left: 0px;
  z-index: 3;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
  height: 70px;
  font-family: Helvetica Neue;
}

#bar-logo {
  float: left;
  padding-right: 1%;
}

.menu-item {
  float: left;
  padding: 15px;
} */
#logoutButton {
  color: var(--red-color) !important;
}

#logoutButton:hover {
  color: var(--red-dark-color) !important;
}

#loginLink:hover :not(.redText) {
  color: var(--green-dark-color) !important;
}

#loginLink.redText:hover {
  color: var(--red-dark-color) !important;
}

.nav-link.activatable {
  color: #fff !important;
}

.menu-word:not(.redText):not(.nav-link.activatable) {
  color: rgba(255, 255, 255, 0.8) !important;

  /* text-align: center;
  text-decoration: none;
  font-size: 20px;
  display: block;
  line-height: 40px; */
}

.menu-word-drop {
  color: #ffffff82 !important;
}

.menu-word:not(.activatable):hover {
  color: #1b9cfc !important;
  cursor: pointer !important;
}

.menu-word-drop:hover {
  color: #1b9cfc !important;
  cursor: pointer !important;
  background-color: rgba(255, 255, 255, 0.08);
}

.menu-brand {
  color: white !important;
}

.menu-drop-div {
  background: #293442;
}

.menu-brand:hover {
  cursor: pointer !important;
}

/* Ungültige Links */

/* #navbarDropdownMenuLink, */
